import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { AddGuardianComponent } from './add-guardian.component'
import { SharedModule } from '../shared.module'
import { LoaderComponent } from '../loader/loader.component'

@NgModule({
  declarations: [AddGuardianComponent],
  imports: [CommonModule, SharedModule, LoaderComponent],
  exports: [AddGuardianComponent]
})
export class AddGuardianModule {}
