<app-popup (popupClose)="closeAddGuardian()">
  <h3 class="popup__header">
    <span>{{ 'organization.add_guardian' | translate }}</span>
    <div class="popup__header-button-wrapper">
      <div class="popup__header-buttons">
        <button mat-icon-button (click)="closeAddGuardian()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </h3>
  <div class="popup__body ns-form ns-light">
    <p
      class="ns-add-guardian-description ns-text"
      [innerHTML]="'add_user.send_registration_link_to_guardians' | translate : { group: group.name }"
    ></p>
    <p class="ns-link-block-header ns-subtitle small">{{ 'add_user.registration_link' | translate }}:</p>
    <div class="ns-copy-url" appInteractive (click)="copy()">
      <span class="ns-url ns-text small">{{ registrationLink }}</span>
      <app-button theme="transparent" size="small" format="text">{{ 'action.copy' | translate }}</app-button>
    </div>
  </div>
</app-popup>
