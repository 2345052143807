import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'

import { UtilsService } from '../../../core/services'
import { environment } from '../../../../environments/environment'
import { SnackbarService } from '../../components/snackbar/snackbar.service'
import { DashboardState } from '../../../module/dashboard/states/dashboard.state'
import { Organization } from '../../../core/interfaces/organization/organization'
import { SelectedGroup } from '../../../core/interfaces/organization/selected-group'

@Component({
  selector: 'app-add-guardian',
  styleUrls: ['./add-guardian.component.scss'],
  templateUrl: './add-guardian.component.html',
  animations: []
})
export class AddGuardianComponent implements OnInit {
  @SelectSnapshot(DashboardState.organization) organization: Organization

  @Input() group: SelectedGroup

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

  registrationLink: string

  constructor(
    private translate: TranslateService,
    private utils: UtilsService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    this.registrationLink = `
      ${environment.baseAppURL}/join/${this.organization.onboarding_code}/open/${this.group.onboarding_code}
    `
  }

  copy(): void {
    this.utils.copyToClipBoard(this.registrationLink)
    this.snackbarService.success(this.translate.instant('toaster.copied'))
  }

  closeAddGuardian(): void {
    this.close.emit(false)
  }
}
